<template>
  <div class="card-head">
    <i :class="`iconfont ${icon} icon-size`" v-show="icon"/>
    <span class="card-head-title">{{ title }}</span>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "CardHeader",
  props: {
    title: String,
    icon: String,
  },
};
</script>
<style lang="less" scoped>
.card-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &-title {
      flex: 1 0;
      font-size: 18px;
      white-space: nowrap;
    }
    .icon-size {
      font-size: 32px;
      margin-right: 10px;
    }
  }
</style>