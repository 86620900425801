<template>
    <div :id="id" :style="{width: `${width}`, height: `${height}`}" ></div>
</template>
<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/chart/pie')
require('echarts/lib/chart/funnel')
require('echarts/lib/chart/bar')
require('echarts/lib/component/title')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/legend')
require('echarts/lib/component/grid')
require('echarts/lib/component/toolbox')
export default {
  name: 'Echarts',
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      required: false,
      default: '100%'
    },
    height: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  methods: {
    init () {
      this.$nextTick(() => {
        const el = document.getElementById(this.id)
        if (el) {
          const echartsContainer = echarts.init(el)
          echartsContainer.setOption(this.options)
          echartsContainer.resize()
          window.addEventListener('resize', function () {
            echartsContainer.resize()
          })
        }
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>
